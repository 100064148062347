import React from 'react'; 
import Stickers from '../Images/Stickers.png'; 
import Product from '../Images/Product.png';
import Flyers from '../Images/Flyers.png';
import Labels from '../Images/Labels.png';
import Business from '../Images/Business.png';
import spot from '../Images/spot.png';
import Flyers2 from '../Images/Flyers2.png';
import Brochure from '../Images/Brochure.png';
import add from '../Images/add-square.png';

const products = [
  { id: 1, name: 'Stickers & Labels', paragraph: 'Lorem ipsum dolor sit amet consectetur. Feugiat nam eget sodales nisi dignissim.' , price: 'From £8.50', imageUrl: Stickers },
  { id: 2, name: 'Product packaging', paragraph: 'Lorem ipsum dolor sit amet consectetur. Feugiat nam eget sodales nisi dignissim.' , price: 'From £8.50', imageUrl: Product },
  { id: 3, name: 'Flyers', paragraph: 'Lorem ipsum dolor sit amet consectetur. Feugiat nam eget sodales nisi dignissim.' , price: 'From £8.50', imageUrl: Flyers },
  { id: 4, name: 'Stickers & Labels', paragraph: 'Lorem ipsum dolor sit amet consectetur. Feugiat nam eget sodales nisi dignissim.' , price: 'From £8.50', imageUrl: Labels },
  { id: 5, name: 'Business Card', paragraph: 'Lorem ipsum dolor sit amet consectetur. Feugiat nam eget sodales nisi dignissim.' , price: 'From £8.50', imageUrl: Business },
  { id: 6, name: 'spot uv printing', paragraph: 'Lorem ipsum dolor sit amet consectetur. Feugiat nam eget sodales nisi dignissim.' , price: 'From £8.50', imageUrl: spot },
  { id: 7, name: 'Flyers', paragraph: 'Lorem ipsum dolor sit amet consectetur. Feugiat nam eget sodales nisi dignissim.' , price: 'From £8.50', imageUrl: Flyers2 },
  { id: 8, name: 'Brochure', paragraph: 'Lorem ipsum dolor sit amet consectetur. Feugiat nam eget sodales nisi dignissim.' , price: 'From £8.50', imageUrl: Brochure },

];

const ProductCard = ({ product }) => {
  return (
    <div className="flex flex-col items-start bg-white rounded-lg shadow-lg mx-2 my-2">
      <div className="w-full">
        <img src={product.imageUrl} alt={product.name} className="w-full h-auto object-cover mb-4" />
      </div>
      <div className="text-start pl-5">
        <h3 className="text-md mb-2" style={{ fontFamily: 'arial' }}>{product.name}</h3>
        <p className="text-xs mr-2 mb-7" style={{ fontFamily: 'arial', color: '#8C8C8C' }}> {product.paragraph} </p>
        <div className="flex flex-row items-center justify-between  mb-5 ">
          <p className=" " style={{ fontFamily: 'arial', fontSize:'13px' }}>{product.price}</p>
          <div className="flex flex-row items-center gap-2">
            <p className="" style={{ color: '#EC008C', fontFamily: 'arial', fontSize:'13px' }}>
              Shop Now
            </p>
            <button>
              <img src={add} className="w-4 h-4 mr-2" alt="Add" />
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

const Carousel = () => {
  return (
    <div className="flex flex-col   bg-gray-100 py-12 px-2 md:px-16 xl:px-48">
      <div className=" flex flex-col items-center">
      <h2 className="text-5xl  font-semibold mb-14 mt-12" style={{ fontFamily: 'arial', color: '#191919' }}>All Categories</h2>
      </div>
      <div className="relative    ">
        <div className="grid grid-cols-1 md:grid-cols-2 xl:grid-cols-4 gap-2">
          {products.map((product, index) => (
            <div key={product.id}>
              <ProductCard product={product} />
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default Carousel;
