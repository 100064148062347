import React from 'react';
import fixedImage from '../Images/ImageMessag.png'; // Replace with your actual image path

const FixedImageMessage = () => {
  return (
    <button className="fixed bottom-0 right-0 mb-4  pr-8">
      <img src={fixedImage} alt="Fixed Message" className="w-10  xl:w-14 h-auto" />
    </button>
  );
};

export default FixedImageMessage;
