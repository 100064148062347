import React, { useState } from "react";
import leftArrow from '../Images/arrowleft.png'; 
import rightArrow from '../Images/arrowright.png'; 
import shoppingCart from '../Images/arrow-shop.png'; 
import massege from '../Images/arrow-massege.png'; 
import TrophyIcon from '../Images/cup.svg'; 
import TruckIcon from '../Images/car.svg'; 
import TagIcon from '../Images/wallet.png';



const OnlinePrintingShop = () => {

  const [currentSlide, setCurrentSlide] = useState(0);

  const slides = [

    (
      <div className=" px-6 flex flex-col  md:flex-row justify-between items-center" >
      <div className="flex-1">
        <h1 className="text-white font-bold text-4xl md:text-4xl xl:text-6xl leading-tight mb-4 px-0 xl:px-20">
          Online Printing Shop All In One
        </h1>
        <p className="text-white text-opacity-80 mb-8 px-0 xl:px-20">
          Lorem Ipsum Dolor Sit Amet Consectetur. Dolor Cursus Mattis Rhoncus Natoque Risus Proin Pharetra Lacus Vitae.
        </p>
        <div className="flex items-center ">
        <button className=" text-white py-3 px-3 xl:px-6 rounded-full font-medium  transition duration-300 ml-0 xl:ml-20 flex items-center gap-2 xl:gap-3" style={{backgroundColor:'#EC008C'}}>
          <p className=" text-sm ">Shop Now</p>
          <img src={shoppingCart} alt="Shop" className= "w-4 xl:w-5 h-auto " /> 
        </button>

        <img src={massege} alt="Description" className="w-8 h-auto ml-6" />
        </div>

      </div>
      <div className="flex-1 mt-10 md:mt-0 mr-0 xl:mr-16">
        <div className="flex justify-end md:justify-end">
          <div className="max-w-md ">
            <div className="bg-white rounded-lg p-6">
              
              <div className="flex justify-between items-center mb-4">
                <div className="w-32 h-32 bg-gray-200 rounded-lg"></div>
                <div className="w-32 h-32 bg-gray-300 rounded-lg"></div>
              </div>
              <div className="h-2 bg-gray-200 rounded mb-4"></div>
              <div className="h-2 bg-gray-300 rounded mb-4"></div>
              <div className="h-2 bg-gray-200 rounded"></div>
            </div>
          </div>
        </div>
      </div>
    </div>
    ),
    (
      <div className=" px-6 flex flex-col  md:flex-row justify-between items-center" style={{height:'294px'}}>
      <div className="flex-1">
        <h1 className="text-white font-bold text-4xl md:text-4xl xl:text-6xl leading-tight mb-4 px-0 xl:px-20">
          Online Printing Shop All In One
        </h1>
        <p className="text-white text-opacity-80 mb-8 px-0 xl:px-20">
          Lorem Ipsum Dolor Sit Amet Consectetur. Dolor Cursus Mattis Rhoncus Natoque Risus Proin Pharetra Lacus Vitae.
        </p>
        <div className="flex items-center ">
        <button className=" text-white py-3 px-3 xl:px-6 rounded-full font-medium  transition duration-300 ml-0 xl:ml-20 flex items-center gap-2 xl:gap-3" style={{backgroundColor:'#EC008C'}}>
          <p className=" text-sm ">Shop Now</p>
          <img src={shoppingCart} alt="Shop" className= "w-4 xl:w-5 h-auto " /> 
        </button>

        <img src={massege} alt="Description" className="w-8 h-auto ml-6" />
        </div>

      </div>
       </div>
    ),
    (
      <div className=" px-6 flex flex-col  md:flex-row justify-between items-center" >
      <div className="flex-1">
        <h1 className="text-white font-bold text-4xl md:text-4xl xl:text-6xl leading-tight mb-4 px-0 xl:px-20">
          Online Printing Shop All In One
        </h1>
        <p className="text-white text-opacity-80 mb-8 px-0 xl:px-20">
          Lorem Ipsum Dolor Sit Amet Consectetur. Dolor Cursus Mattis Rhoncus Natoque Risus Proin Pharetra Lacus Vitae.
        </p>
        <div className="flex items-center ">
        <button className=" text-white py-3 px-3 xl:px-6 rounded-full font-medium  transition duration-300 ml-0 xl:ml-20 flex items-center gap-2 xl:gap-3" style={{backgroundColor:'#EC008C'}}>
          <p className=" text-sm ">Shop Now</p>
          <img src={shoppingCart} alt="Shop" className= "w-4 xl:w-5 h-auto " /> 
        </button>

        <img src={massege} alt="Description" className="w-8 h-auto ml-6" />
        </div>

      </div>
      <div className="flex-1 mt-10 md:mt-0 mr-0 xl:mr-16">
        <div className="flex justify-end md:justify-end">
          <div className="max-w-md ">
            <div className="bg-white rounded-lg p-6">
              
              <div className="flex justify-between items-center mb-4">
                <div className="w-32 h-32 bg-gray-200 rounded-lg"></div>
                <div className="w-32 h-32 bg-gray-300 rounded-lg"></div>
              </div>
              <div className="h-2 bg-gray-200 rounded mb-4"></div>
              <div className="h-2 bg-gray-300 rounded mb-4"></div>
              <div className="h-2 bg-gray-200 rounded"></div>
            </div>
          </div>
        </div>
      </div>
    </div>
    ),

  ];

  const nextSlide = () => setCurrentSlide((currentSlide + 1) % slides.length);

  // Function to navigate to the previous slide
  const prevSlide = () => setCurrentSlide((currentSlide - 1 + slides.length) % slides.length);


  return (
    <div>
    <div className="w-full h-auto flex flex-col  justify-center py-20 px-12 xl:px-24" style={{backgroundColor: '#8a75b3'}}>
        <div className="absolute left-0 ml-8 xl:ml-20 flex items-center justify-center h-full cursor-pointer" onClick={prevSlide}>
        <img src={leftArrow} alt="Left Arrow" className="h-auto w-5" />
      </div>

      {slides[currentSlide]}


    
      <div className="absolute right-0 mr-8 xl:mr-20 flex items-center justify-center h-full cursor-pointer" onClick={nextSlide}>
        <img src={rightArrow} alt="Right Arrow" className="h-auto w-5" />
      </div>


      <div className="flex items-center justify-end space-x-2">
  {slides.map((_, index) => (
    <span key={index}
          style={{
            width: currentSlide === index ? '20px' : '8px',
            backgroundColor: currentSlide === index ? '#ec008c' : 'white',
            opacity: currentSlide === index ? '2' : '0.5',
          }}
          
    ></span>
  ))}
</div>




    </div>


    <div className=" flex flex-col  md:flex-row justify-between items-start md:items-center  px-20 md:px-16 xl:px-48 space-y-12 md:space-y-0  bg-white py-8 md:py-8">
      <div className="flex flex-row items-center gap-4 pl-0 md:pl-1">
        <img src={TrophyIcon} alt="Premium Quality" className="h-8 w-8" />
        <p className="text-black text-xs xl:text-xl  mt-2">Premium Quality Made In The UK</p>
      </div>
      <div className="flex flex-row items-center gap-4">
        <img src={TruckIcon} alt="Fast Delivery" className="h-8 w-8" />
        <p className="text-black text-xs xl:text-xl mt-2">Fast Production And Delivery</p>
      </div>
      <div className="flex flex-row items-center gap-4 ">
        <img src={TagIcon} alt="Low Price" className="h-8 w-8" />
        <p className="text-black text-xs xl:text-xl mt-2">Low Price And Low Quantity Orders</p>
      </div>
    </div>


  

</div>

  );
};

export default OnlinePrintingShop;
