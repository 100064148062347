import React from 'react';

// Assuming you have the images imported
import sampleImage from '../Images/sampleImage.png';  // Replace with your actual image path
import rightArrowIcon from '../Images/rightArrowIcon.png';  // Replace with your actual image path

const SampleSection = () => {
  return (
    <div className="relative flex flex-col md:flex-row items-center justify-start bg-cover bg-center rounded-lg shadow-lg mx-4 md:mx-16 xl:mx-48 my-10 h-full"
      style={{ 
          backgroundImage: `url(${sampleImage})`,
          backgroundSize: 'cover',  // Changed to 'cover' to ensure the background covers the entire div
          height: 'auto',  // Changed to 'auto' to ensure the content dictates the height
          minHeight: '395px',  // Ensures there's a minimum height similar to your original design
      }}
    >
      <div className="p-6 mt-28 md:mt-0 md:pl-16 text-center  xl:text-start">
        <p className="text-sm md:text-md font-light text-white mb-4 md:mb-10" style={{ fontFamily: 'arial' }}>
          Get The Feel Of Our High Quality Print And Materials Order A Free Sample Kit Today
        </p>
        <h2 className="text-3xl md:text-5xl font-semibold text-white mb-6 md:mb-20" style={{ fontFamily: 'arial' }}>Get Free Sample</h2>
        <button className="inline-flex items-center px-4 md:px-6 py-2 md:py-3 border border-white text-xs md:text-sm font-medium rounded-full shadow-sm text-white">
          Get A Sample
          <img src={rightArrowIcon} alt="arrow" className="ml-2 md:ml-3 h-3 md:h-4 w-3 md:w-4" />
        </button>
      </div>
    </div>
  );
};

export default SampleSection;
