import React, { useState, useEffect, useRef } from 'react';
import logo from '../Images/logo.png'; 
import bag from '../Images/bag-happy.png';
import search from '../Images/searchNew.png';
import arrowDownImage from '../Images/arrowdown.png'; 
import menuIcon from '../Images/more.png';

const Header = () => {

  const [isExpanded, setIsExpanded] = useState(false);
  const [isMobile, setIsMobile] = useState(window.innerWidth < 640); // 640px is a common breakpoint for mobile screens
  const inputRef = useRef(null);
  const [isMenuVisible, setIsMenuVisible] = useState(false);
  

  // Update 'isMobile' based on screen width
  useEffect(() => {
      const handleResize = () => {
          setIsMobile(window.innerWidth < 640);
      };

      window.addEventListener('resize', handleResize);
      return () => {
          window.removeEventListener('resize', handleResize);
      };
  }, []);

  const expandedWidth = 'w-64'; // Expanded width
  const collapsedWidth = 'w-24'; // Collapsed width

  const inputStyle = {
    transition: 'width 300ms ease-in-out', // Ensure transition applies to width change
    backgroundColor: "#E1E1E1",
    width: isExpanded ? '16rem' : '6rem', // Use rem units equivalent to Tailwind's w-64 and w-24
  };

   const toggleInput = () => {
    if (!isMobile) {
      setIsExpanded(!isExpanded); // Toggle the expanded state
    }
  };

  useEffect(() => {
      if (!isMobile) {
          const handleClickOutside = (event) => {
              if (inputRef.current && !inputRef.current.contains(event.target)) {
                  setIsExpanded(false);
              }
          };

          document.addEventListener('mousedown', handleClickOutside);
          return () => {
              document.removeEventListener('mousedown', handleClickOutside);
          };
      }
  }, [isMobile, inputRef]);

  const toggleMenu = () => {
    setIsMenuVisible(!isMenuVisible); 
  };
  
    
    const inputClasses = isExpanded ? "pl-4 pr-2 py-2 w-64 outline-none" : "pl-4 pr-2 py-2 w-24 outline-none";
  
    
  return (
    <div>
    <header className="bg-white p-4 flex justify-between items-center px-4 sm:px-28 border-b border-gray-300">
   
      <img src={logo} alt="Printtrio Logo" className="h-8 sm:h-12" />

    
      <div className="flex items-center space-x-4 space-x-8">
       
      <div className="flex items-center bg-white border rounded-full overflow-hidden" style={{backgroundColor: "#E1E1E1"}}>        <button className="p-2">
        <img src={search} alt="Search" className="h-4 sm:h-5 w-4 sm:w-5" />
      </button>
      <input
        ref={inputRef}
        className="pl-4 pr-2 py-2 outline-none" // Removed width classes
        type="search"
        placeholder="Search"
        onClick={toggleInput}
        style={inputStyle} // Applied the dynamic style
      />

    </div>

        
    <button className="flex items-center justify-center text-xs sm:text-base">
        Join
        </button>
        <button className="flex items-center justify-center">
        <img src={bag} alt="Printtrio bag" className="h-5 sm:h-6 w-5 sm:w-6" />
        </button>
      </div>
   
    </header>




    <div className="flex justify-end pr-2">
  <button onClick={toggleMenu} className="p-2 xl:hidden">
    <img src={menuIcon} alt="Menu" className="h-6 w-6" />
  </button>
</div>
<nav className={`bg-white py-3 ${isMenuVisible ? 'block' : 'hidden'} xl:block`}>

<ul className="flex flex-col xl:flex-row justify-between items-center px-4 sm:px-28">

    <div className="flex flex-col xl:flex-row items-center space-x-0 xl:space-x-4  space-y-2 xl:space-y-0">
      <li className="text-black hover:text-pink-500 cursor-pointer flex items-center text-xs sm:text-base ">
        Products <img src={arrowDownImage} alt="Arrow down" className="ml-2 w-2 h-auto" />
      </li>
      <li className="text-black hover:text-pink-500 cursor-pointer flex items-center text-xs sm:text-base ">
        Collections <img src={arrowDownImage} alt="Arrow down" className="ml-3 mt-2 w-2 h-auto" />
      </li>
    </div>

    <div className="flex flex-col xl:flex-row items-center space-x-0 xl:space-x-4 sm:space-x-0 space-y-2 xl:space-y-0 mt-2 sm:mt-0">
      <li className="text-black hover:text-pink-500 cursor-pointer text-xs sm:text-base mt-2 xl:mt-0">Stickers</li>
      <li className="text-black hover:text-pink-500 cursor-pointer text-xs sm:text-base">Business Cards</li>
      <li className="text-black hover:text-pink-500 cursor-pointer text-xs sm:text-base">Flyers</li>
      <li className="text-black hover:text-pink-500 cursor-pointer text-xs sm:text-base">Packages</li>
      <li className="text-black hover:text-pink-500 cursor-pointer text-xs sm:text-base">Post Cards</li>
      <li className="text-black hover:text-pink-500 cursor-pointer text-xs sm:text-base">Gift Vouchers</li>
    </div>
  </ul>
</nav>


 </div>

  );
};

export default Header;
