import React, { useState } from 'react';
import weddingCollectionImage from '../Images/wedding.png';
import retailCollectionImage from '../Images/Business-Card.webp'; // Add your retail collection image path
import christmasCollectionImage from '../Images/wedding.png'; // Add your christmas collection image path
import exhibitionCollectionImage from '../Images/wedding.png'; // Add your exhibition collection image path
import shopNowIcon from '../Images/shop.png';

const CollectionSection = () => {
    const [currentCollection, setCurrentCollection] = useState('wedding');

    const getButtonClass = (collection) => {
      return `font-semibold hover:text-gray-300  ${currentCollection === collection ? 'text-black' : 'text-gray-500'}`;
  };


    // Function to render content based on the current collection
    const renderContent = () => {
        switch (currentCollection) {
            case 'wedding':
                return (
<div 
  className="flex flex-col md:flex-row items-center rounded-xl shadow-lg overflow-hidden backdrop-blur-sm w-full"
  style={{ 
      backgroundImage: `url(${weddingCollectionImage})`,
      backgroundSize: 'cover', // Changed to 'cover' for better responsiveness
      backgroundPosition: 'center',
      backgroundRepeat: 'no-repeat',
  }}
>
  <div className="p-6 md:p-28 md:pr-16 lg:pr-72 flex-1 w-full">
      <h2 className="text-3xl md:text-5xl text-white font-semibold" style={{ fontFamily: 'arial'}}>Wedding Collection</h2>
      <div className="md:pr-20 lg:pr-80">
          <p className="mt-4 md:mt-6 text-sm md:text-base text-gray-200" style={{ fontFamily: 'arial'}}>
              Lorem ipsum dolor sit amet consectetur. Dolor cursus mattis rhoncus natoque risus proin pharetra lacus vitae.
          </p>
      </div>
      <button 
          className="mt-6 md:mt-8 text-xs md:text-sm inline-flex items-center bg-white px-4 md:px-6 py-2 md:py-3 rounded-full tracking-wide shadow"
          style={{ fontFamily: 'arial', color: '#191919' }}
      >
          Shop Now
          <img src={shopNowIcon} alt="Shop Now" style={{ marginLeft: '8px', width: '16px', height: '16px' }} />
      </button>
  </div>
</div>
                );
            case 'retail':
                // Update with retail collection content
                return (
<div 
  className="flex flex-col md:flex-row items-center rounded-xl shadow-lg overflow-hidden backdrop-blur-sm"
  style={{ 
      backgroundImage: `url(${weddingCollectionImage})`,
      backgroundSize: 'cover', // Changed to 'cover' for better responsiveness
      backgroundPosition: 'center',
      backgroundRepeat: 'no-repeat',
  }}
>
  <div className="p-6 md:p-28 md:pr-16 lg:pr-72 flex-1">
      <h2 className="text-3xl md:text-5xl text-white font-semibold" style={{ fontFamily: 'arial'}}>Retail Collection</h2>
      <div className="md:pr-20 lg:pr-80">
          <p className="mt-4 md:mt-6 text-sm md:text-base text-gray-200" style={{ fontFamily: 'arial'}}>
              Lorem ipsum dolor sit amet consectetur. Dolor cursus mattis rhoncus natoque risus proin pharetra lacus vitae.
          </p>
      </div>
      <button 
          className="mt-6 md:mt-8 text-xs md:text-sm inline-flex items-center bg-white px-4 md:px-6 py-2 md:py-3 rounded-full tracking-wide shadow"
          style={{ fontFamily: 'arial', color: '#191919' }}
      >
          Shop Now
          <img src={shopNowIcon} alt="Shop Now" style={{ marginLeft: '8px', width: '16px', height: '16px' }} />
      </button>
  </div>
</div>
              );
            case 'christmas':
                // Update with christmas collection content
                return (
                    <div 
                    className="flex flex-col md:flex-row items-center rounded-xl shadow-lg overflow-hidden backdrop-blur-sm"
                    style={{ 
                        backgroundImage: `url(${weddingCollectionImage})`,
                        backgroundSize: 'cover', // Changed to 'cover' for better responsiveness
                        backgroundPosition: 'center',
                        backgroundRepeat: 'no-repeat',
                    }}
                  >
                    <div className="p-6 md:p-28 md:pr-16 lg:pr-72 flex-1">
                        <h2 className="text-3xl md:text-5xl text-white font-semibold" style={{ fontFamily: 'arial'}}>Christmas Collection</h2>
                        <div className="md:pr-20 lg:pr-80">
                            <p className="mt-4 md:mt-6 text-sm md:text-base text-gray-200" style={{ fontFamily: 'arial'}}>
                                Lorem ipsum dolor sit amet consectetur. Dolor cursus mattis rhoncus natoque risus proin pharetra lacus vitae.
                            </p>
                        </div>
                        <button 
                            className="mt-6 md:mt-8 text-xs md:text-sm inline-flex items-center bg-white px-4 md:px-6 py-2 md:py-3 rounded-full tracking-wide shadow"
                            style={{ fontFamily: 'arial', color: '#191919' }}
                        >
                            Shop Now
                            <img src={shopNowIcon} alt="Shop Now" style={{ marginLeft: '8px', width: '16px', height: '16px' }} />
                        </button>
                    </div>
                  </div>
              );
            case 'exhibition':
                // Update with exhibition collection content
                return (
                    <div 
                    className="flex flex-col md:flex-row items-center rounded-xl shadow-lg overflow-hidden backdrop-blur-sm"
                    style={{ 
                        backgroundImage: `url(${weddingCollectionImage})`,
                        backgroundSize: 'cover', // Changed to 'cover' for better responsiveness
                        backgroundPosition: 'center',
                        backgroundRepeat: 'no-repeat',
                    }}
                  >
                    <div className="p-6 md:p-28 md:pr-16 lg:pr-72 flex-1">
                        <h2 className="text-3xl md:text-5xl text-white font-semibold" style={{ fontFamily: 'arial'}}>Exhibition Collection</h2>
                        <div className="md:pr-20 lg:pr-80">
                            <p className="mt-4 md:mt-6 text-sm md:text-base text-gray-200" style={{ fontFamily: 'arial'}}>
                                Lorem ipsum dolor sit amet consectetur. Dolor cursus mattis rhoncus natoque risus proin pharetra lacus vitae.
                            </p>
                        </div>
                        <button 
                            className="mt-6 md:mt-8 text-xs md:text-sm inline-flex items-center bg-white px-4 md:px-6 py-2 md:py-3 rounded-full tracking-wide shadow"
                            style={{ fontFamily: 'arial', color: '#191919' }}
                        >
                            Shop Now
                            <img src={shopNowIcon} alt="Shop Now" style={{ marginLeft: '8px', width: '16px', height: '16px' }} />
                        </button>
                    </div>
                  </div>
              );
            default:
                return null;
        }
    };

    return (
        <div className="flex flex-col bg-gray-100 ">
  <div className="  p-6 md:p-12 px-4 md:px-12 xl:px-48 mb-10 w-full">
      {/* Title Section */}
      <div className="flex flex-col mb-6 md:mb-8 ">
          <h1 className="text-3xl md:text-5xl font-bold text-center text-black mb-6 md:mb-10" style={{ fontFamily: 'arial'}}>Collection Section</h1>
         <div className="flex flex-row justify-center gap-6 ">
              <button className={getButtonClass('wedding')} onClick={() => setCurrentCollection('wedding')}>Wedding Collection</button>
              <button className={getButtonClass('retail')} onClick={() => setCurrentCollection('retail')}>Retail Collection</button>
              <button className={getButtonClass('christmas')} onClick={() => setCurrentCollection('christmas')}>Christmas Collection</button>
              <button className={getButtonClass('exhibition')} onClick={() => setCurrentCollection('exhibition')}>Exhibition Collection</button>
          </div>
      </div>
      {renderContent()}
  </div>
</div>
    );
};

export default CollectionSection;
