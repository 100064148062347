import React, { useState } from 'react';
import RateImage from '../Images/rate.png';
import trustpilot from '../Images/star.png';
import Verified from '../Images/Verified.png';
import leftArrowIcon from '../Images/right.png'; 
import rightArrowIcon from '../Images/left.png';

const ReviewCard = ({ title, reviewText, date }) => (
    <div className="flex flex-col items-center xl:items-start p-2 w-full ">
      <div className="flex flex-col xl:flex-row items-center gap-8">
        <img src={RateImage} alt='RateImage' className="w-20 h-auto" />
        <div className="flex flex-row items-center gap-2">
          <img src={Verified} alt='Verified' className="w-4 h-auto" />
          <p className="" style={{ fontFamily: 'arial', color: '#191919',fontSize: '13px' }}>Verified</p>
        </div>
      </div>
      <div className=" w-full text-center xl:text-start px-8 md:px-40 xl:px-0 mb-5 xl:mb-0">
        <h2 className="text-xs font-bold mt-3" style={{ fontFamily: 'arial'}}>{title}</h2>
        <h4 className="text-sm mt-2" style={{color: '#8C8C8C', fontFamily: 'arial'}}>{reviewText}</h4>
        <p className="text-sm text-black mt-4" style={{ fontFamily: 'arial'}}>{date}</p>
      </div>
    </div>
  );
  


const Rate = () => {

    const [activeIndex, setActiveIndex] = useState(0);
    const [arrowClicked, setArrowClicked] = useState(false);

    const reviews = [
        {
          title: 'Lorem ipsum dolor sit amet conse...',
          text: 'The website is very user friendly and easy to use. shipping is relatively fast.',
          date: 'September 06, 2023',
        },
        {
          title: 'Lorem ipsum dolor sit amet conse...',
          text: 'The website is very user friendly and easy to use. shipping is relatively fast.',
          date: 'September 06, 2023',
        },
        {
          title: 'Lorem ipsum dolor sit amet conse...',
          text: 'The website is very user friendly and easy to use. shipping is relatively fast.',
          date: 'September 06, 2023',
        },
        {
          title: 'Lorem ipsum dolor sit amet conse...',
          text: 'The website is very user friendly and easy to use. shipping is relatively fast.',
          date: 'September 06, 2023',
        },
        // Add additional reviews as needed
      ];

      const goNext = () => {
        setActiveIndex(prevIndex => (prevIndex + 1) % reviews.length);
        setArrowClicked(true);
      };
    
      const goPrev = () => {
        setActiveIndex(prevIndex => (prevIndex === 0 ? reviews.length - 1 : prevIndex - 1));
        setArrowClicked(true);
      };
    
      // Calculate the starting index for the visible reviews
      const startIndex = activeIndex < 5 ? 0 : activeIndex - 3;
    
      // Get the 5 reviews to display, wrapping around if necessary
      const visiblereviews = [];
      for (let i = 0; i < 5; i++) {
        visiblereviews.push(reviews[(startIndex + i) % reviews.length]);
      }
    
      // Determine the index of the active product within the visible reviews
      const activeVisibleIndex = visiblereviews.findIndex(product => product.id === reviews[activeIndex].id);
      

  return (
    <div className=" py-16" style={{backgroundColor: '#F0F0F0'}}>

      <div className=" w-full">
        
        <div className=" text-center mb-16">
          <h1 className="text-3xl xl:text-5xl font-semibold mb-8" style={{ fontFamily: 'arial', color: '#191919' }} >Great reviews, Happy Customers</h1>
          <div className="flex flex-col xl:flex-row justify-center text-center gap-8">
            <div className="flex flex-row justify-center text-center gap-3">
          <p className="font-medium" style={{ fontFamily: 'arial', color: '#191919' }}>Excellent  </p>
          <img src={RateImage} alt= 'RateImage' className="w-32 h-6  " />
          </div>
          <p className="font-thin mt-1" style={{ fontFamily: 'arial', color: '#191919',fontSize: '13px' }}>Based on 4,400 reviews</p>
          <div className=" flex flex-row justify-center text-center gap-1">
          <img src={trustpilot} alt= 'RateImage' className=" w-6 h-auto  " />
          <p className="font-medium" style={{ fontFamily: 'arial', color: '#191919' }}>Trustpilot</p>
          </div>
          </div>
        </div>
    
        <div className="flex flex-row items-center gap-4 ">
          <button onClick={goPrev} className="hidden xl:inline-flex absolute left-0 z-10 ml-32">
            <img src={leftArrowIcon} alt="Previous" className="h-8 w-8" />
          </button>
          <div className="flex flex-col xl:flex-row px-0 xl:px-44 overflow-x-auto mx-0 xl:mx-14 mt-8 gap-14">
          {reviews.map((review, index) => (
            <ReviewCard key={index} title={review.title}  reviewText={review.text} date={review.date} />
          ))}
          </div>
         <button onClick={goNext} className="hidden xl:inline-flex absolute right-0 z-10 mr-32">
            <img src={rightArrowIcon} alt="Next" className="h-8 w-8" />
          </button>
        </div>
      </div>
    </div>
  );
};

export default Rate;
