import React from 'react';
import PromoBanner from '../Components/PromoBanner';
import Navbar from '../Components/Navbar';
import Slider from '../Components/Slider';
import Products from '../Components/Products';
import AllCategories from '../Components/AllCategories';
import CollectionSection from '../Components/CollectionSection';
import PrintShop from '../Components/PrintShop';
import Rate from '../Components/Rate';
import Sample from '../Components/Sample';
import Subscribe from '../Components/SubscribeSection';
import FixedImageMessage from '../Components/FixedImageMessage';
import Footer from '../Components/Footer';




const Home = () => {
    return (

<div>
   <PromoBanner />
   <Navbar />
   <Slider />
   <Products />
   <AllCategories />
   <CollectionSection />
   <PrintShop />
   <Rate />
   <Sample />
   <Subscribe />
   <FixedImageMessage />
   <Footer />
</div>
    )}

export default Home;
