import React from "react";
import logo from '../Images/logo.png'; 
import VisaLogo from '../Images/visa.png'; // Make sure to have these logos in your project
import MasterCardLogo from '../Images/mastercard.png';
import MaestroLogo from '../Images/maestro.png';
import AmericanExpressLogo from '../Images/American_Express.png';

const Footer = () => {
  return (
    <div>
   <footer className="flex flex-col xl:flex-row justify-between  px-8 xl:px-28 py-10 md:py-16 gap-8 xl:gap-28" style={{backgroundColor: '#FCFCFC'}}>
        <div className="space-y-4 w-full  xl:w-1/3 ">
          <img src={logo} alt="Logo" className="h-12 mx-auto xl:mx-0 mb-0 md:mb-10 xl:mb-0" />
          <p className="text-gray-500 text-xs text-center px-0 md:px-20 xl:px-0 xl:text-left">
            Lorem ipsum dolor sit amet consectetur. Scele risque ridiculus eu suspendisse euismod nibh quisque at. Velit
            convallis diam mauris leo. Magna blandit dapibus present massa.
          </p>
        </div>


        <div className="flex flex-col md:flex-row justify-between gap-8 md:gap-12">
          <div className="space-y-4 text-md">
            <h3 className="text-gray-900 font-bold">About Us</h3>
            <nav>
    <ul className="space-y-2 text-sm">
      <li><a href="/history" className="text-gray-600 hover:text-gray-900 transition">History</a></li>
      <li><a href="/certificates" className="text-gray-600 hover:text-gray-900 transition">Certificates</a></li>
      <li><a href="/awards" className="text-gray-600 hover:text-gray-900 transition">Our Awards</a></li>
      <li><a href="/environment" className="text-gray-600 hover:text-gray-900 transition">Environment</a></li>
      <li><a href="/plant-list" className="text-gray-600 hover:text-gray-900 transition">Plant List</a></li>
    </ul>
  </nav>
          </div>
          <div className="space-y-4 text-md">
            <h3 className="text-gray-900 font-bold">Help</h3>
            <nav>
    <ul className="space-y-2 text-sm">
      <li><a href="/delivery" className="text-gray-600 hover:text-gray-900 transition">Delivery Information</a></li>
      <li><a href="/faqs" className="text-gray-600 hover:text-gray-900 transition">FAQs</a></li>
    </ul>
  </nav>
          </div>
          <div className="space-y-4 text-md">
            <h3 className="text-gray-900 font-bold">Contact Us</h3>
            <nav>
    <ul className="space-y-2 text-sm">
      <li><a href="/concern" className="text-gray-600 hover:text-gray-900 transition">Concern</a></li>
      <li><a href="/products" className="text-gray-600 hover:text-gray-900 transition">Free Products</a></li>
      <li><a href="/contact" className="text-gray-600 hover:text-gray-900 transition">Contact Us</a></li>
    </ul>
  </nav>
          </div>
          <div className="space-y-4 text-md">
            <h3 className="text-gray-900 font-bold">Art Work</h3>
            <nav>
    <ul className="space-y-2 text-sm">
      <li><a href="/design-service" className="text-gray-600 hover:text-gray-900 transition">Artwork Design Service</a></li>
      <li><a href="/create" className="text-gray-600 hover:text-gray-900 transition">Create Your Own</a></li>
      <li><a href="/supplying-artwork" className="text-gray-600 hover:text-gray-900 transition">Supplying Artwork</a></li>
      <li><a href="/sizes" className="text-gray-600 hover:text-gray-900 transition">Printing Paper Sizes</a></li>
      <li><a href="/templates" className="text-gray-600 hover:text-gray-900 transition">Templates</a></li>
    </ul>
  </nav>
          </div>
          <div className="space-y-4 text-md">
            <h3 className="text-gray-900 font-bold">My Account</h3>
            <nav>
    <ul className="space-y-2 text-sm">
      <li><a href="/login" className="text-gray-600 hover:text-gray-900 transition">Login</a></li>
      <li><a href="/register" className="text-gray-600 hover:text-gray-900 transition">Register</a></li>
      <li><a href="/delivery-info" className="text-gray-600 hover:text-gray-900 transition">Delivery Information</a></li>
      <li><a href="/track-delivery" className="text-gray-600 hover:text-gray-900 transition">Trackable Delivery</a></li>
      <li><a href="/white-label" className="text-gray-600 hover:text-gray-900 transition">White Label</a></li>
      <li><a href="/payment-queries" className="text-gray-600 hover:text-gray-900 transition">Payment Queries</a></li>
    </ul>
  </nav>
          </div>
          </div>
     
    </footer>



    <div className=" mx-auto flex flex-col md:flex-row justify-between items-center px-24 md:px-8 xl:px-28 mb-5 text-center md:text-left">
        <div className="text-gray-600 text-xs mb-4 md:mb-0">
          ©2023 printtrio. All Rights Reserved
        </div>
        <div className="flex flex-wrap justify-center md:justify-between items-center gap-4">
          <a href="/privacy-policy" className="text-gray-600 hover:text-gray-900 transition-colors text-xs">Privacy & Policy</a>
          <a href="/terms-conditions" className="text-gray-600 hover:text-gray-900 transition-colors text-xs">Terms & Condition</a>
          <img src={MasterCardLogo} alt="MasterCard" className="h-6" />
          <img src={VisaLogo} alt="Visa" className="h-6" />
          <img src={AmericanExpressLogo} alt="American Express" className="h-6" />
          <img src={MaestroLogo} alt="Maestro" className="h-6" />
        </div>
      </div>
    </div>
  );
};

export default Footer;
