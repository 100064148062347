import React from 'react';

const PromoBanner = () => {
  return (
    <div className="w-full bg-pink-500">
      <div className="text-center py-2">
       
        <p className="text-white text-sm sm:text-xxs">
          20% off on Notepads! Use code: printpade2020
        </p>
      </div>
    </div>
  );
};

export default PromoBanner;
