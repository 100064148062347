import React from 'react';
import sampleVideoMp4 from '../video/digital.mp4';



const Step = ({ number, title, color, isLast  }) => {
    let borderColorClass = "";
    let textColorClass = "";
    
    switch (color) {
        case "pink":
            borderColorClass = "border-pink-500";
            textColorClass = "text-pink-500";
            break;
        case "blue":
            borderColorClass = "border-blue-500";
            textColorClass = "text-blue-500";
            break;
        case "yellow":
            borderColorClass = "border-yellow-500";
            textColorClass = "text-yellow-500";
            break;
        default:
            borderColorClass = "border-gray-500";
            textColorClass = "text-gray-500";
    }

    return (
        <div className="flex  gap-5 ">
            <div className="flex flex-col items-center ">
            <div className={`rounded-full h-14 w-14 flex items-center justify-center  ${borderColorClass} border-2`}>
                <span style={{ color: '#191919', fontWeight: 'bold', fontFamily: 'Arial', fontSize: '18px' }}>{number}</span>
            </div>
            {!isLast && (
    <div className="w-0.5 border-r-2 border-dashed " style={{ height: '50px', borderColor: '#8C8C8C' }}></div>
)}

            </div>
            <div className={`ml-4 mt-3 font-bold   ${textColorClass}` } style={{ fontFamily: 'Arial', fontSize: '20px' }}>
                {title}
            </div>
            
           
        </div>
    );
};
  
const CardWithVideo = ({ firstNumber, firstText, secondNumber, secondText }) => {
    return (
        <div className="flex flex-col items-center justify-center p-10 relative " style={{ fontFamily: 'Arial' }}>
<div className="video-frame rounded-lg overflow-hidden   mx-auto" >
    <video className="" controls>
        <source src={sampleVideoMp4} type="video/mp4" />
    </video>
</div>

            <div className=" shadow-lg flex p-1 md:p-2 xl:p-3   w-52 md:w-80 xl:w-1/2   absolute bottom-0 mb-0 md:mb-8 xl:mb-12 " style={{ backgroundColor: '#8AC9FF', color: '#191919', borderRadius: '16px', transform: 'translateX(-50%)', left: '61%',bottom: '75%'}}>
                <div className="flex items-center gap-1 " >
                    <div className="text-xl md:text-3xl xl:text-4xl font-bold">{firstNumber}</div>
                    <div className="ml-2 text-xs md:text-lg xl:text-xl" >{firstText}</div>
                </div>
                <div className="border-r border-black mx-2 md:mx-5 xl:mx-6" style={{ height: '50px' }}></div>
                <div className="flex items-center gap-1">
                    <div className="text-xl md:text-3xl xl:text-4xl font-bold">{secondNumber}</div>
                    <div className="ml-2 text-xs md:text-lg xl:text-xl">{secondText}</div>
                </div>
            </div>
        </div>
    );
};
  

const Printtrio = () => {
  return (
    <div className="w-full  py-10">
     
        
     <div className="flex flex-col xl:flex-row justify-between items-center mt-14 mb-12 w-full">
  <h1 className="text-xl md:text-3xl xl:text-5xl px-0 xl:px-48 mb-5 xl:mb-0" style={{ fontFamily: 'Arial', color: '#191919' }}>Printtrio, Your Local Online Print Shop</h1>
  <p className="text-base text-gray-500 px-10 xl:px-20 mr-0 xl:mr-14" style={{ maxWidth: '40rem' }}>Lorem ipsum dolor sit amet consectetur. Dolor cursus mattis rhoncus natoque risus proin pharetra lacus vitae. Adipiscing pretium dui vitae interdum consectetur nulla neque. Sodales volutpat faucibus tellus pretium dui.</p>
</div>

<div className="flex flex-col xl:flex-row items-center justify-between bg-white px-0 xl:px-36">
<div className="flex flex-col items-start justify-center p-12 w-full">
    <Step number="01" title="Online Order" color="pink" />
    <Step number="02" title="Request Rec" color="blue" />
    <Step number="03" title="Job Done" color="yellow" isLast />
</div>
<div className="p-1 " style={{ fontFamily: 'Arial' , fontSize: '14px' }}>
<CardWithVideo 
    firstNumber="10" 
    firstText="Years Experience" 
    secondNumber="60" 
    secondText="Type Of Services" 
    videoSrc="../video/digital.mp4" // Replace with the path to your video file
/>
</div>
</div>

       

    </div>
  );
};

export default Printtrio;
