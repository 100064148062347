import React, { useState, useEffect } from 'react';
import leftArrowIcon from '../Images/right.png'; 
import rightArrowIcon from '../Images/left.png'; 
import productImage1 from '../Images/card1.png'; 
import productImage2 from '../Images/card2.png';
import productImage3 from '../Images/card3.png';
import productImage4 from '../Images/card4.png';
import productImage5 from '../Images/card5.png';
import add from '../Images/add-square.png';

const products = [
  { id: 1, name: 'Business Card', price: 'from £8.50', imageUrl: productImage1 },
  { id: 2, name: 'Flyers', price: 'from £8.50', imageUrl: productImage2 },
  { id: 3, name: 'DL Vouchers', price: 'from £8.50', imageUrl: productImage3 },
  { id: 4, name: 'Business Card', price: 'from £8.50', imageUrl: productImage4 },
  { id: 5, name: 'Stickers & Labels', price: 'from £8.50', imageUrl: productImage5 },
  { id: 6, name: '1', price: 'from £8.50', imageUrl: productImage1 },
  { id: 7, name: '2', price: 'from £8.50', imageUrl: productImage2 },
  { id: 8, name: '3', price: 'from £8.50', imageUrl: productImage3 },
  { id: 9, name: '4', price: 'from £8.50', imageUrl: productImage4 },
  { id: 10, name: '5', price: 'from £8.50', imageUrl: productImage5 },
];

const ProductCard = ({ product }) => {
  return (
<div className="flex flex-col items-start bg-white rounded-lg shadow-lg mx-4 my-2">
  <div className="w-full">
    <img src={product.imageUrl} alt={product.name} className="w-full h-auto object-cover mb-4" />
  </div>
  <div className="text-start pl-5 w-full">
    <h3 className="text-md mb-5" style={{ fontFamily: 'Arial' }}>{product.name}</h3>
    <div className="flex flex-row items-center justify-between mb-5 mr-3">
      <p className="text-sm" style={{ fontFamily: 'Arial' }}>{product.price}</p>
      <div className="flex flex-row items-center gap-2">
        <p className="text-sm" style={{ color: '#EC008C', fontFamily: 'Arial' }}>
          Order Now
        </p>
        <button>
          <img src={add} className="w-4 h-4" alt="Add to cart" />
        </button>
      </div>
    </div>
  </div>
</div>

  );
};

const Carousel = () => {
  const [activeIndex, setActiveIndex] = useState(0);
  const [arrowClicked, setArrowClicked] = useState(false);
  const [isXLScreen, setIsXLScreen] = useState(window.innerWidth >= 1280);
  

  const goNext = () => {
    setActiveIndex(prevIndex => (prevIndex + 1) % products.length);
    setArrowClicked(true);
  };

  const goPrev = () => {
    setActiveIndex(prevIndex => (prevIndex === 0 ? products.length - 1 : prevIndex - 1));
    setArrowClicked(true);
  };

  useEffect(() => {
    const handleResize = () => setIsXLScreen(window.innerWidth >= 1280);
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  const visibleProducts = isXLScreen ? [] : [...products]; // Default to showing all products on smaller screens

  if (isXLScreen) {
    const startIndex = activeIndex < 5 ? 0 : activeIndex - 3;
    // Loop to get the 5 products to display, wrapping around if necessary
    for (let i = 0; i < 5; i++) {
      visibleProducts.push(products[(startIndex + i) % products.length]);
    }
  }

  // Determine the index of the active product within the visible products
  const activeVisibleIndex = visibleProducts.findIndex(product => product.id === products[activeIndex].id);

  return (
    <div className="flex flex-col items-center justify-center bg-gray-100 py-12 px-0  xl:px-44">
      <h2 className="text-2xl md:text-4xl xl:text-5xl font-semibold mb-14 mt-12" style={{ fontFamily: 'arial', color: '#191919' }}>Our Popular Products</h2>
      <div className="relative w-full flex items-center justify-center">
        <button onClick={goPrev} className="hidden xl:inline-flex absolute -left-10 z-10">
          <img src={leftArrowIcon} alt="Previous" className="h-8 w-8" />
        </button>
        <div className="grid grid-cols-1 md:grid-cols-2 xl:grid-cols-5 gap-7 xl:gap-0 justify-center snap-x snap-mandatory w-full px-0 md:px-14 xl:px-0">
          {visibleProducts.map((product, index) => (
            <div key={product.id} className={`snap-center transition-transform duration-300 ease-in-out  ${arrowClicked && index === activeVisibleIndex ? 'scale-110' : 'scale-100' }`}>
              <ProductCard product={product} />
            </div>
          ))}
        </div>
        <button onClick={goNext} className="hidden xl:inline-flex absolute -right-10 z-10">
          <img src={rightArrowIcon} alt="Next" className="h-8 w-8" />
        </button>
        
      </div>
    </div>
  );
};

export default Carousel;
