import React from 'react';
import rightArrowIcon from '../Images/black.png'; // Replace with your actual arrow image path

const Subscribe = () => {
  return (
    <div className="flex justify-center bg-white px-4 sm:px-6 md:px-16 xl:px-48 mb-20">
      <div className="flex flex-col justify-between sm:flex-row rounded-2xl shadow-xl items-center w-full  bg-blue-300 gap-4 md:gap-0 xl:gap-14 py-8 sm:py-14">
        <div className="text-xl sm:text-4xl font-bold px-4 pl-0 md:pl-10 text-center sm:text-left" style={{ fontFamily: 'arial'}}>
          <h2>Subscribe Now</h2>
        </div>
        <div className="flex flex-col justify-between sm:flex-row items-center gap-4 md:gap-14 mr-0 xl:mr-10">
          <div className="flex flex-col items-center sm:items-start space-y-4 w-full ">
            <p className="text-xs">Email</p>
            <hr className="border-t border-gray-400 w-full" />
            <div className="flex flex-row items-start w-full px-2 xl:px-0 ">
              
              <input
                type="checkbox"
                id="consent"
                name="consent"
                className="h-4 w-4 "
              />
              <label htmlFor="consent" className="ml-2 text-xs text-center sm:text-left">
                I want to receive exclusive discounts, latest updates, and industry insights via email.
              </label>
            </div>
          </div>
          
            <button className="flex items-center px-4 md:px-8  xl:px-12 py-2 sm:py-3 bg-white text-black rounded-full mt-4 sm:mt-6 gap-2 mr-0 md:mr-10 xl:mr-0">
              Submit
              <img src={rightArrowIcon} alt="Submit" className="ml-2 w-4 sm:w-5 h-auto" />
            </button>
         
        </div>
      </div>
    </div>
  );
};

export default Subscribe;
